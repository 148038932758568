import React, { Component } from "react";
import { graphql } from 'gatsby';
import { Card } from 'semantic-ui-react';
import Layout from "../layouts";
import ProjectSection from '../components/project-section';
import get from 'lodash/get';

import './index.css';


export default class Portfolio extends Component {
  sortProjects = (projectA, projectB) => {
    const yearDiff = Number(projectB.node.year) - Number(projectA.node.year);
    if (yearDiff !== 0) return yearDiff;

    return new Date(projectB.node.updatedAt).getTime() - new Date(projectA.node.updatedAt).getTime();
  }
  mapProjects = ({ node }) => <ProjectSection key={node.id} node={node} />;
  render() {
    const projects = get(this.props.data, 'allContentfulProject.edges');
    return (
      <Layout title="Portfolio">
        <div>
          <Card.Group id="portfolio">
            {projects && projects.sort(this.sortProjects).map(this.mapProjects)}
          </Card.Group>
        </div>
      </Layout>
    );
  }
}

export const portfolioQuery = graphql`
  query PortfolioQuery {
    allContentfulProject {
      edges {
        node {
          id
          year
          title
          updatedAt
          technologies
          icon {
            resize(width: 290, height: 290) {
              src
            }
          }
        }
      }
    }
  }
`;
