import React, { Component } from 'react';
import Link from 'gatsby-link';
import { Card } from 'semantic-ui-react';
import TechnologyIcons from './technology-icons';

import './project-section.css';


export default class ProjectSection extends Component {
  render = () => {
    const { id, title, technologies, icon } = this.props.node;
    return (
      <Link className="project-section" to={`/portfolio/${id}`}>
        <Card>
          <img alt="" src={icon.resize.src} />
          <Card.Content>
            <Card.Header>{title}</Card.Header>
            {/* <Card.Meta></Card.Meta> */}
            <Card.Description>
              <TechnologyIcons technologies={technologies} defaultClassName="fa-lg" />
            </Card.Description>
          </Card.Content>
          {/* <Card.Content extra></Card.Content> */}
        </Card>
      </Link>
    );
  }
}