import React from "react";


const TechnologyIcons = ({ technologies, defaultClassName }) => {
  const getClassName = (tech) => {
    if (typeof tech !== "string") return "";
    return iconClassNames[tech.replace(/[\s-]/g, "").toLowerCase()];
  }

  const uniqueClassNames = Array.from(new Set(technologies.map(getClassName)));
  return <>
    {uniqueClassNames.sort().map((name, ind) => <span key={ind} className="devicon">
      <i className={`${name} ${defaultClassName}`} />
    </span>)}
  </>;
};

export default TechnologyIcons;


const iconClassNames = {
  html: "fab fa-html5",
  html5: "fab fa-html5",
  js: "fab fa-js-square",
  javascript: "fab fa-js-square",
  django: "devicon-django-plain",
  css: "fab fa-css3-alt",
  css3: "fab fa-css3-alt",
  react: "fab fa-react",
  reactjs: "fab fa-react",
  reactnative: "fab fa-react",
  node: "icon-nodejs",
  nodejs: "icon-nodejs",
  vue: "fab fa-vuejs",
  vuejs: "fab fa-vuejs",
  python: "fab fa-python",
  android: "devicon-android-plain",
  numpy: "devicon-numpy-original",
  ios: "fab fa-apple",
  apple: "fab fa-apple",
  docker: "fab fa-docker",
  psql: "icon-postgres",
  postgres: "icon-postgres",
  postgresql: "icon-postgres",
  ethereum: "fab fa-ethereum",
  chrome: "fab fa-chrome",
  vr: "fas fa-vr-cardboard",
};
